import { Box } from "@mui/material"
import React from "react"
import { Client, EditClientRequest } from "../../api/Clients"
import { SessionData } from "../../api/SessionData"
import { AuthHolder } from "../../utils/Auth"
import { State } from "../../utils/UseChange"
import { LoadingBackdrop, LoadingController } from "../LoadingBackdrop/LoadingBackdrop"
import { NavigatorController } from "../Navigator/Navigator"
import ClientEditor from "./ClientEditor"

type Props = {
  authHolder: AuthHolder
  client: State<Client>
  navigator: NavigatorController
  sessionData: State<SessionData>
}
export default function EditClient({ authHolder, navigator, sessionData, client }: Props) {
  const loading = React.useRef(new LoadingController())

  const save = (newClient: Client) => {
    loading.current.show()
    EditClientRequest(authHolder, newClient).then((savedClient) => {
      loading.current.success().then(() => {
        var newData = sessionData.value
        newData.clients = newData.clients.map(c => c.clientID === savedClient.clientID ? savedClient : c)
        sessionData.set({ ...newData })
        client.set(savedClient)
        navigator.pop()
      })
    }).catch(err => {
      console.error(err);
      loading.current.fail();
    })
  }
  return <Box>
    <LoadingBackdrop controller={loading.current} />
    <ClientEditor sessionData={sessionData} client={client.value} saveClient={save} />
  </Box>
}