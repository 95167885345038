import { URLSearchParams } from "url";
import { APIError } from "./Errors";

export function NotEmpty(variable: any, variableName: string) {
  Assert(
    variable === "" || variable === undefined,
    `${variableName} cannot be empty`
  );
}

export function Assert(condition: boolean, message: string) {
  if (condition) {
    throw message || "Assertion failed";
  }
}

export const GetRequest = async <Response,>(host: string, endpoint: string, query?: URLSearchParams, reqHeaders?: HeadersInit): Promise<Response | APIError> => {
  NotEmpty(host, "host");
  NotEmpty(endpoint, "endpoint");
  return await FetchRequest<Response>("GET", { host, endpoint, query, reqHeaders });
}

export const DeleteRequest = async <Response,>(host: string, endpoint: string, reqHeaders?: HeadersInit): Promise<Response | APIError> => {
  NotEmpty(host, "host");
  NotEmpty(endpoint, "endpoint");

  return await FetchRequest("DELETE", { host, endpoint, reqHeaders });
};

export const PostRequest = async <Response,>(host: string, endpoint: string, body: object, reqHeaders?: HeadersInit): Promise<Response | APIError> => {
  NotEmpty(host, "host");
  NotEmpty(endpoint, "endpoint");
  NotEmpty(body, "body");

  return await FetchRequest<Response>("POST", { host, endpoint, body, reqHeaders });
};

export const PutRequest = async <Response,>(host: string, endpoint: string, body: object, reqHeaders?: HeadersInit): Promise<Response | APIError> => {
  NotEmpty(host, "host");
  NotEmpty(endpoint, "endpoint");
  NotEmpty(body, "body");

  return await FetchRequest("PUT", { host, endpoint, body, reqHeaders });
};

export const PatchRequest = async <Response,>(host: string, endpoint: string, body: object): Promise<Response | APIError> => {
  NotEmpty(host, "host");
  NotEmpty(endpoint, "endpoint");
  NotEmpty(body, "body");

  return await FetchRequest("PATCH", { host, endpoint, body });
};

function getCookie(name: string): string {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue ?? "";
}

async function FetchRequest<mResponse>(method: string, { host, endpoint, body, query, reqHeaders }
  : { host: string, endpoint: string, body?: object, query?: URLSearchParams, reqHeaders?: HeadersInit }): Promise<mResponse | APIError> {
  // async function FetchRequest<mResponse>(host: string, method: string, endpoint: string, body?: object, query?: URLSearchParams, reqHeaders?: HeadersInit): Promise<mResponse | APIError> {
  NotEmpty(host, "host");
  NotEmpty(method, "method");
  NotEmpty(endpoint, "endpoint");

  try {
    var headers: HeadersInit = {
      ...reqHeaders,
      "Content-type": "application/json",
      "X-CSRFTOKEN": getCookie("csrftoken"),
    }

    var options: RequestInit = {
      credentials: "include",
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : body,
    };

    //TODO add abort signal
    const response = await fetch(
      host + "/" + endpoint + (query ? "?" + query.toString() : ""),
      options
    );

    if (!response.ok) {
      var err = await response.json();
      return new APIError(err.message, response)
    }

    try {
      return await response.json() as mResponse;
    } catch (ignored) {
      return new APIError("failed to read response body");
    }
  } catch (err) {
    return new APIError("error while fetching");
  }
}
