import { Check, Close } from "@mui/icons-material";
import { Backdrop, CircularProgress, Fade } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { UseMount } from "../../utils/UseChange";

export class LoadingController {
  setOpen: Dispatch<SetStateAction<boolean>> = () => console.error('loading ignored')
  setSuccess: Dispatch<SetStateAction<boolean | undefined>> = () => console.error('loading ignored')

  bind(setOpen: Dispatch<SetStateAction<boolean>>, setSuccess: Dispatch<SetStateAction<boolean | undefined>>) {
    this.setOpen = setOpen;
    this.setSuccess = setSuccess;
  }
  show() {
    this.setSuccess(undefined)
    this.setOpen(true)
  }
  success() {
    return new Promise<void>(async (resolve) => {
      this.setSuccess(true)
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.setOpen(false)
      await new Promise(resolve => setTimeout(resolve, 200))
      resolve()
    })
  }
  fail() {
    return new Promise<void>(async (resolve) => {
      this.setSuccess(false)
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.setOpen(false)
      await new Promise(resolve => setTimeout(resolve, 200))
      resolve()
    })
  }
  dismiss() {
    return new Promise<void>(async (resolve) => {
      this.setSuccess(undefined)
      this.setOpen(false)
      resolve()
    })
  }
}

type Props = {
  controller: LoadingController
  defaultOpen?: boolean
}

export function LoadingBackdrop({ controller, defaultOpen }: Props) {
  const [open, setOpen] = React.useState(defaultOpen ?? false)
  const [success, setSuccess] = React.useState<boolean | undefined>(undefined)
  UseMount(() => {
    controller.bind(setOpen, setSuccess)
  })
  return <Fade in={open}><Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    {success == undefined && <CircularProgress color="inherit" />}
    {success === true && <Check sx={{ width: 50, height: 50 }} />}
    {success === false && <Close sx={{ width: 50, height: 50 }} />}
  </Backdrop>
  </Fade>
}