import { DescriptionOutlined, PendingActionsOutlined, PriceCheck, ReplyOutlined } from "@mui/icons-material"
import { List, Box, Stack, Typography, IconButton, Divider } from "@mui/material"
import { Payment } from "../../api/Payments"
import { SessionData } from "../../api/SessionData"
import { AuthHolder } from "../../utils/Auth"
import { State } from "../../utils/UseChange"
import { ClientMessage, WhatsappMessage } from "../../utils/Whatsapp"
import { NavigatorController } from "../Navigator/Navigator"
import PaymentDetails from "./PaymentDetails"
import { PaymentReceiptViewer } from "./PaymentReceiptViewer"

type Props = {
  authHolder: AuthHolder
  sessionData: State<SessionData>
  navigator: NavigatorController
  filter?: (payment: Payment) => boolean
}

export default function PaymentsList({ authHolder, sessionData, navigator, filter }: Props) {

  const openPaymentDetails = (payment: Payment) => {
    navigator.push('Detalhes do pagamento', <PaymentDetails authHolder={authHolder} navigator={navigator} sessionData={sessionData} payment={payment} />)
  }

  const openReceipt = (payment: Payment) => {
    navigator.push('Recibo', <PaymentReceiptViewer authHolder={authHolder} paymentID={payment.paymentID} />)
  }

  const renderPayment = (payment: Payment, idx: number) => {
    //TODO client could be undefined
    //TODO find in map instead of filter through array
    const client = sessionData.value.clients.find(c => c.clientID === payment.clientID)
    if (client == undefined) {
      console.error('undefined client')
      return
    }

    const date = new Date();
    date.setDate(1);
    date.setMonth(payment.month);
    const monthShort = date.toLocaleString('pt-BR', { month: 'short' }).replace('\.', '').toLocaleUpperCase();
    const monthLong = date.toLocaleString('pt-BR', { month: 'long' }).replace('\.', '').replace(/^./, str => str.toUpperCase());
    const indexIconSize = 40

    return <Box key={idx} sx={{ cursor: 'pointer' }} onClick={() => openPaymentDetails(payment)}>
      <Stack direction='row' alignItems='center' spacing={2} display='flex' margin={2}>
        {payment.receiptTS == undefined
          ? <PendingActionsOutlined sx={{ height: indexIconSize, width: indexIconSize, color: '#FF9400' }} />
          : <PriceCheck sx={{ height: indexIconSize, width: indexIconSize, color: '#0F580B' }} />}
        <Stack direction='column' alignItems='start' flexGrow='1'>
          <Typography variant='h6'>{client.name}</Typography>
          <Typography>R${payment.totalPrice}</Typography>
        </Stack>
        <Stack direction='row' alignItems='end'>
          {payment.receiptTS == undefined
            ? <IconButton onClick={(e) => {
              e.stopPropagation();
              WhatsappMessage(client.phone, ClientMessage(client.name, monthLong, payment.notes, payment.paymentID));
            }}><ReplyOutlined sx={{ transform: 'scale(-1, 1)', width: 35, height: 35 }} /></IconButton>
            : <IconButton onClick={(e) => {
              e.stopPropagation();
              openReceipt(payment);
            }}><DescriptionOutlined /></IconButton>}
          {/* TODO fix year gambi */}
          <Stack direction='column' alignItems='center' marginTop={1}><Typography>{monthShort}</Typography><Typography>{payment.year - 2000}</Typography></Stack>
        </Stack>
      </Stack>
      <Divider />
    </Box>
  }

  return <List sx={{ width: '100%' }}>
    <Typography variant="h5" sx={{ margin: '30px', marginBottom: '10px', marginTop: '30px', fontWeight: '700' }}>Pagamentos pendentes</Typography>
    <Divider />
    {sessionData.value.payments.filter(filter ? filter : () => true).filter(p => p.receiptTS == undefined).map((payment, idx) => renderPayment(payment, idx))}
    <Typography variant="h5" sx={{ margin: '30px', marginBottom: '10px', marginTop: '30px', fontWeight: '700' }}>Pagamentos recebidos</Typography>
    <Divider />
    {sessionData.value.payments.filter(filter ? filter : () => true).filter(p => p.receiptTS != undefined).map((payment, idx) => renderPayment(payment, idx))}
  </List >
}