import { Dispatch, SetStateAction } from "react";

export const emailRegexComplete = /^.*@.*\..*$/;

export const phoneRegexComplete = /^\([0-9]{2}\) ([0-9] )?[0-9]{4}[\-]?[0-9]{4}$/;
export const updatePhone = (value: string, setPhone: Dispatch<SetStateAction<string>>) => {
  value = buildPhone(value)
  setPhone(value)
}

export const buildPhone = (value: string) => {
  // Remove non digits
  value = value.replaceAll(/[^0-9]/g, '')

  // 00123456789 -> (00) 1 2345-6789
  if (value.length >= 11) {
    value = "(" + value.slice(0, 2) + ") " + value.slice(2, 3) + " " + value.slice(3, 7) + "-" + value.slice(7)
  }
  // 0012345 -> (00) 1234-5
  else if (value.length >= 7) {
    value = "(" + value.slice(0, 2) + ") " + value.slice(2, 6) + "-" + value.slice(6)
  }
  // 001 -> (00) 1 
  else if (value.length > 2) {
    value = "(" + value.slice(0, 2) + ") " + value.slice(2)
  }

  return value.substring(0, 16)
}

export const getPhoneParts = (value: string) => {
  // Remove non digits
  value = value.replaceAll(/[^0-9]/g, '')

  if (value.length < 10 || value.length > 12) {
    throw new Error("invalid phone number");
  }

  return [value.substring(0, 2), value.substring(2)]
}

export const cpfRegexComplete = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/;
export const updateCPF = (value: string, setCPF: Dispatch<SetStateAction<string>>) => {
  // Remove non digits
  value = value.replaceAll(/[^0-9]/g, '')

  if (value.length >= 10) {
    value = value.substring(0, 3) + "." + value.substring(3, 6) + "." + value.substring(6, 9) + "-" + value.substring(9)
  }
  else if (value.length >= 7) {
    value = value.substring(0, 3) + "." + value.substring(3, 6) + "." + value.substring(6)
  }
  else if (value.length >= 4) {
    value = value.substring(0, 3) + "." + value.substring(3)
  }

  setCPF(value.substring(0, 14))
}
