import { Add } from "@mui/icons-material"
import { Box, Fab, Typography } from "@mui/material"
import { SessionData } from "../../api/SessionData"
import { AuthHolder } from "../../utils/Auth"
import { State } from "../../utils/UseChange"
import { NavigatorController } from "../Navigator/Navigator"
import CreatePayment from "./CreatePayment"
import PaymentsList from "./PaymentsList"


type Props = {
  authHolder: AuthHolder
  sessionData: State<SessionData>
  navigator: NavigatorController
}

export default function Payments({ authHolder, sessionData, navigator }: Props) {

  const createPayment = () => {
    navigator.push("Criar pagamento", <CreatePayment authHolder={authHolder} sessionData={sessionData} navigator={navigator} />)
  }

  return <Box>
    {sessionData.value.payments.length === 0
      ? <Box display='flex' width='100%' justifyContent='center' marginTop={3}>
        <Typography>Adicione um pagamento!</Typography>
      </Box>
      : <PaymentsList authHolder={authHolder} navigator={navigator} sessionData={sessionData} />
    }
    <Fab color='primary' onClick={() => createPayment()} sx={{ position: 'fixed', bottom: 72, right: 16 }} aria-label="add">
      <Add />
    </Fab>
  </Box >
}