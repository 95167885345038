import { Close } from "@mui/icons-material"
import { Modal, Box } from "@mui/material"
import React from "react"
import { CSSProperties } from "styled-components"

export const PrivacyPolicies = ({ children, style }: { children: string, style?: CSSProperties | undefined }) => {
  const [privacyPolicies, setPrivacyPolicies] = React.useState(false)
  return <>
    <Modal
      open={privacyPolicies}
      onClose={() => setPrivacyPolicies(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClick={() => setPrivacyPolicies(false)}
      keepMounted
      sx={{ display: privacyPolicies ? undefined : 'none' }}
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "90%",
          maxWidth: '1000px',
          height: "80%",
          display: 'flex',
          flexDirection: 'column',
          outline: 0,
        }}>
        <Close
          onClick={() => setPrivacyPolicies(false)}
          sx={{
            color: 'black',
            fontSize: '3rem',
            marginLeft: 'auto',
            marginBottom: '20px',
            marginRight: '10px',
          }} />
        <Box sx={{
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          overflowY: 'scroll',
          overflowX: 'hidden',
          pr: 2,
          pl: 2,
        }}>
          <embed
            style={{
              width: '200%',
              maxWidth: '900px',
              height: '100%',
              marginRight: '-100px',
              marginLeft: '-100px',
            }}
            src="https://docs.google.com/document/d/e/2PACX-1vRpCmOfcUrR3uNnbxluB8pN4LPfBm084olzGMfuruJP9TOuLu2xWaYEwrF-UiVK3dPXf9OTyty2vcik/pub?embedded=true"
          />
        </Box>
      </Box>
    </Modal>
    <a style={style} href="#" onClick={() => setPrivacyPolicies(true)}>{children}</a>
  </>
}