import { Box, Card, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function WhyUse() {
  const theme = useTheme()
  const navigate = useNavigate();

  const CCard = ({ title, text }: { title: string, text: string }) => {
    return <>
      <Box
        onClick={() => {
          navigate("/painel");
        }}
        sx={{
          width: '90%',
          margin: '3% 3%',
          padding: '1%',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '50%',
          }}>
          <Typography flexGrow={1} fontSize={theme.mixins.cm(4, 2.5)} fontWeight='medium' color='textSecondary'>{title}</Typography>
          <img
            style={{
              margin: '0 10px',
              height: theme.mixins.ch(1.5),
              transform: 'rotate(180deg)',
            }}
            src='arrow.svg' />
        </Box>
        <Typography fontSize={theme.mixins.cm(3, 1.5)}>{text}</Typography>
      </Box>
    </>
  }

  return <Box sx={{ width: '100%', height: '100%', padding: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Box
      sx={{
        margin: '2% 0',
      }}>
      <Typography fontWeight='medium' fontSize={theme.mixins.cm(6, 3)}>Porque você deve usar o Dia1</Typography>
    </Box>
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexGrow: 1,
        }}>
        <CCard title="Imagem profissional" text="Reforce a imagem de que você é um profissional sério e de alta credibilidade" />
        <CCard title="Receba com mínimo esforço" text="Automatizamos vários passos do processo de cobrança para liberar seu tempo" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexGrow: 1,
        }}>
        <CCard title="Adaptado à você" text="Somos integrados ao WhatsApp para que você e seus clientes resolvam tudo por lá" />
        <CCard title="Dê adeus às planilhas" text="O Dia1 te diz todos os números financeiros que você precisa (e organiza os recibos)" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexGrow: 1,
        }}>
        <CCard title="Dê a sua cara às mensagens" text="Automatizamos a cobrança, mas você define a mensagem que será enviada" />
        <CCard title="No celular ou no computador" text="Para quem gosta de resolver tudo pelo celular e para quem gosta do computador" />
      </Box>

    </Box>
  </Box>
}