import * as React from 'react';
import Box from '@mui/material/Box';
import { UseMount, UseState } from '../../utils/UseChange';
import { GetPaymentPublicData, PaymentPublic, PostReceiptIntent, PostReceiptCommit, ReceiptStatus } from '../../api/Receipts';
import { useParams } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { LoadingBackdrop, LoadingController } from '../../components/LoadingBackdrop/LoadingBackdrop';
import CompanyAvatar from '../../components/CompanyAvatar/CompanyAvatar';
import { GetLocaleType } from '../../api/User';
import InfoEntry from './InfoEntry';

export default function ReceiptPage() {
  const loading = React.useRef(new LoadingController());
  const { paymentID } = useParams()
  const receipt = UseState<PaymentPublic | undefined>(undefined)

  UseMount(() => {
    loading.current.show()
    GetPaymentPublicData(paymentID!).then(data => {
      console.log(data);
      receipt.set(data);
      loading.current.dismiss()
    }).catch((err) => {
      console.error(err);
      loading.current.fail()
    });
  })

  const date = new Date();
  date.setDate(1);
  date.setMonth(receipt.value?.month ?? 0);
  const month = date.toLocaleString('pt-BR', { month: 'long' }).replace('\.', '');

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList || fileList.length === 0) {
      console.error("no file selected");
      return;
    }
    if (fileList.length > 1) {
      console.error("more than one file selected");
      return;
    }

    console.log(`will upload: ${fileList[0].name}`);

    loading.current.show();
    PostReceiptIntent(paymentID!).then(receiptIntent => {
      //TODO fix cors for GCP bucket
      //TODO handle error
      //TODO limit image size
      //TODO sanitize user input (is it really an image? Is it the correct format?)
      //TODO limit image size on GCP
      fetch(receiptIntent.receiptUploadURL, {
        method: 'PUT',
        body: fileList[0],
        mode: 'cors',
      })
        .then((response) => {
          console.log('upload with success:', response);

          PostReceiptCommit(paymentID!, receiptIntent.receiptUploadPath)
            .then(receiptCommit => {
              console.log(`receipt commit: ${receiptCommit.paymentID}`)
              receipt.set({ ...receipt.value!, receiptStatus: ReceiptStatus.Uploaded });
              loading.current.success();
            })
            .catch(err => {
              console.error(`failed to commit receipt: ${err}`)
              loading.current.fail();
            })

        }).catch((err) => {
          console.error(`failed to upload receipt: ${err}`);
          loading.current.fail();
        })
    }).catch(err => {
      console.error(`failed to create receipt intent: ${err}`);
      loading.current.fail();
    })
  }

  const renderPaymentPending = (receipt: PaymentPublic) => {
    return <Stack direction='column' spacing={1} >
      <Typography variant='subtitle1'>
        Olá, {receipt.shortClientName}!
      </Typography>
      <Typography variant='subtitle1'>
        {receipt.notes}
      </Typography>
      <Typography variant='subtitle1' marginBottom={2}>
        Este é o pagamento referente a {month} de {receipt.year} para {receipt.professionalName}.
      </Typography>
      <InfoEntry name="Valor a pagar" value={`R$ ${receipt.totalPrice},00`} copy={`${receipt.totalPrice},00`} />
      <Divider />
      <InfoEntry name="Número de sessões" value={`${receipt.sessionCount}`} />
      <Divider />
      <InfoEntry name="Mês de referência" value={`${receipt.month + 1}/${receipt.year}`} />
      <Divider />
      <InfoEntry name={`Pix (${GetLocaleType(receipt.pix.keyType)})`} value={receipt.pix.key} copy={receipt.pix.key} />
      <Box width='100%' justifyContent='center' display='flex'>
        <Button
          sx={{ width: '95%' }}
          disabled={receipt.receiptStatus === ReceiptStatus.Uploaded}
          variant='contained'
          color='secondary'
          component='label'>
          Upload file
          <input
            accept="image/*"
            type='file'
            hidden
            onChange={handleUpload}
          />
        </Button>
      </Box>
    </Stack >
  }

  const renderPaymentComplete = () => {
    return <Stack direction='column'>
      <Typography variant='h4'>Comprovante enviado com sucesso!</Typography>
      <Typography variant='subtitle1'>O comprovante desse pagamento já foi enviado.</Typography>
      <Typography variant='subtitle1'>Entre em contato com seu profissional casa tenha ocorrido algum problema.</Typography>
    </Stack>
  }

  return (
    < Box sx={{ width: '100vw', padding: '5%' }} >
      <LoadingBackdrop controller={loading.current} />
      <Stack height='100%' marginLeft='auto' marginRight='auto' display='flex' alignItems='center' minWidth={300} maxWidth={500} direction='column' >
        <CompanyAvatar size={100} />
        {receipt.value != undefined &&
          (
            receipt.value?.receiptStatus === ReceiptStatus.Pending
              ?
              renderPaymentPending(receipt.value)
              :
              renderPaymentComplete()
          )
        }
      </Stack>
    </Box >
  );
}
