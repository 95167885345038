import { API_HOST } from "../constants/Addresses"
import { AuthHolder } from "../utils/Auth"
import { Phone } from "./Entities"
import { APIError } from "./Errors"
import { PostRequest } from "./Utils"

const PixTypes = {
  CPF: 'cpf',
  Phone: 'phone',
  Email: 'email',
  RandomKey: 'random',
}
export type PixData = {
  key: string
  keyType: string
}

export function GetLocaleType(keyType?: string): string {
  switch (keyType) {
    case PixTypes.CPF:
      return 'CPF';
    case PixTypes.Phone:
      return 'Número de telefone';
    case PixTypes.Email:
      return 'Email';
    case PixTypes.RandomKey:
      return 'Chave aleatória';
  }
  return "Chave";
}

export type User = {
  userID: string
  firebaseID: string
  name: string
  phone: Phone
  email: string
  pix: PixData
  profession: string
}

export const CreateUser = async (auth: AuthHolder, user: User): Promise<User> => {
  const idToken = await auth.getToken();

  const result = await PostRequest<User | undefined>(
    `${API_HOST}`,
    `users`,
    {
      "firebaseID": user.firebaseID,
      "name": user.name,
      "phone": user.phone,
      "email": user.email,
      "profession": user.profession,
      "pix": {
        "keyType": user.pix.keyType,
        "key": user.pix.key,
      },
    },
    {
      "authorization": `bearer ${idToken}`
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as User;
  return response;
}