import { Box, Typography, useTheme } from "@mui/material"

export function ForWhom() {
  const theme = useTheme()
  const CCard = ({ title, image }: { title: string, image: string }) => {
    return <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '30%',
        height: '100%',
        flexGrow: 1,
        margin: '5% 5%',
        padding: '5%',
      }}>
      <img src={image} style={{ marginBottom: '5%', height: '70%', width: '100%', flexGrow: 1 }} />
      <Typography height='30%' textAlign='center' fontWeight='light' fontSize={theme.mixins.ch(2)}>{title}</Typography>
    </Box>
  }
  return <Box sx={{ width: '100%', height: '100%', padding: '5%', paddingTop: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Box
      sx={{
        height: '20%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Typography textAlign='center' fontWeight='medium' fontSize={theme.mixins.ch(3)}>Para quem o Dia1 é indicado</Typography>
    </Box>
    <Box sx={{
      flexDirection: 'column',
      height: '80%',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          height: '50%',
          width: '100%',
        }}>
        <CCard title='Personal trainers' image='personal-trainers.svg' />
        <CCard title='Professores particulares' image='teachers.svg' />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          height: '50%',
          width: '100%',
        }}>
        <CCard title='Psicólogos' image='psychologists.svg' />
        <CCard title='Outros Profissionais Liberais' image='general-workers.svg' />
      </Box>
    </Box>
  </Box>
}