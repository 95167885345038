import React, { DependencyList, Dispatch, EffectCallback, SetStateAction } from "react";

export const UseChange = (effect: EffectCallback, deps: DependencyList) => {
  const isMount = React.useRef(true);
  React.useEffect(() => {
    if (isMount.current === false) {
      effect()
    }
    isMount.current = false
  }, deps);
};

export const UseMount = (effect: EffectCallback) => {
  const isMount = React.useRef(true);
  React.useEffect(() => {
    if (isMount.current === true) {
      effect()
    }
    isMount.current = false
  }, []);
};

export class State<S> {
  value: S;
  set: (value: S) => void;
  refactorFunc?: (value: S) => void;
  constructor(value: S, set: Dispatch<SetStateAction<S>>, refactorFunc?: (value: S) => void) {
    this.value = value
    this.refactorFunc = refactorFunc
    this.set = (value: S) => {
      this.refactorFunc != undefined && this.refactorFunc(value)
      set(value)
    }
  }
}

export const UseState = <S,>(initialState: S | (() => S), refactorFunc?: (value: S) => void): State<S> => {
  const [value, set] = React.useState<S>(initialState)

  return new State(value, set, refactorFunc)
}

export class StateArray<S> {
  value: Array<S>
  set: (arr: Array<S>) => void
  refactorFunc?: (array: Array<S>) => void
  constructor(value: Array<S>, set: Dispatch<SetStateAction<Array<S>>>, refactorFunc?: (array: Array<S>) => void) {
    this.value = value
    this.set = (arr: Array<S>) => {
      this.refactorFunc != undefined && this.refactorFunc(arr)
      set(arr)
    }
    this.refactorFunc = refactorFunc
  }

  push(s: S) {
    this.value.push(s)
    this.refactorFunc && this.refactorFunc(this.value)
    this.set(this.value)
  }
  replace(newEl: S, finder: (s: S) => boolean) {
    this.value = this.value.map(el => finder(el) ? newEl : el)
    this.refactorFunc && this.refactorFunc(this.value)
    this.set(this.value)
  }
  unshift(s: S) {
    this.value.unshift(s)
    this.refactorFunc && this.refactorFunc(this.value)
    this.set(this.value)
  }
}

export const UseStateArray = <S,>(initialState?: Array<S> | (() => Array<S>), refactorFunc?: (array: Array<S>) => void): StateArray<S> => {
  const [value, set] = React.useState(initialState ?? Array<S>())
  return new StateArray<S>(value, set, refactorFunc)
}

