import { Phone } from "../api/Entities";
import { openInNewTab } from "./NewTab";

export function ClientMessage(clientName: string, referenceMonth: string, notes: string, paymentID: string) {
  return `Olá ${clientName},\n` +
    (notes.length > 0 ? `\n${notes}\n` : '') +
    `\nAqui estão as informações do pagamento referente ao mês de ${referenceMonth}:\n` +
    `dia1.io/r/${paymentID}`
}

export function SupportMessage() {
  return "Help me!"
}

export function WhatsappMessage(phone: Phone, message: string = "") {
  openInNewTab(`https://wa.me/${phone.countryCode}${phone.regionalCode}${phone.number}?text=${encodeURIComponent(message)}`)
}