import { Box, Button, Divider, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NavigatorController } from "../Navigator/Navigator";
import CreateClient from "../Clients/CreateClient";
import { State } from "../../utils/UseChange";
import { Payment } from "../../api/Payments";
import { SessionData } from "../../api/SessionData";
import { AuthHolder } from "../../utils/Auth";

type Props = {
  authHolder: AuthHolder
  sessionData: State<SessionData>
  injectedClientID?: string
  payment?: Payment
  savePayment: (payment: Payment) => void
  navigator: NavigatorController
}

export default function PaymentEditor({ authHolder, sessionData, navigator, injectedClientID, payment, savePayment }: Props) {
  const defaultSessions = 4
  const [clientID, setClientID] = React.useState<string | undefined>(injectedClientID ?? undefined)
  const [sessions, setSessions] = React.useState<number | undefined>(payment?.sessionCount ?? defaultSessions)
  const [notes, setNotes] = React.useState<string>(payment?.notes ?? '')
  const [showFailures, setShowFailures] = React.useState(false)

  const [date, setDate] = React.useState<Date>(() => {
    let date = new Date()
    date.setDate(date.getDate() + 15)
    return date
  });

  const updateSessions = (newValue: number | string) => {
    newValue = `${newValue}`.replaceAll(/[^0-9]/g, '')
    if (newValue === '') {
      setSessions(undefined)
      return
    }
    setSessions(+newValue)
  }

  const client = sessionData.value.clients.find(c => c.clientID === clientID)

  const save = () => {
    let failed = false
    if (client == undefined) {
      failed = true
    }
    if (sessions == undefined) {
      failed = true
    }
    if (date.getFullYear() < 2020) {
      failed = true
    }

    if (failed) {
      setShowFailures(true)
      return
    }
    setShowFailures(false)

    const totalPrice = sessions! * client!.sessionPrice

    savePayment({
      paymentID: payment?.paymentID ?? '',
      userID: sessionData.value.user.userID,
      clientID: client!.clientID,
      sessionPrice: client!.sessionPrice,
      sessionCount: sessions!,
      totalPrice: totalPrice,
      month: date.getMonth(),
      year: date.getFullYear(),
      receiptStatus: payment?.receiptStatus ?? '',
      receiptPath: payment?.receiptPath ?? '',
      receiptTS: payment?.receiptTS,
      notes: notes,
    })
  }

  return <Box justifyContent='center' display='flex'>
    <Stack direction='column' margin={6} spacing={2} width='100%'>
      <Typography>Cliente</Typography>
      <Select
        value={clientID ?? ''}
        label={'Cliente'}
        error={showFailures && clientID == undefined}
        onChange={(event) => {
          if (event.target.value === 'new') {
            setClientID(undefined)
            navigator.push('Novo cliente', <CreateClient authHolder={authHolder} navigator={navigator} sessionData={sessionData} setClientID={setClientID} />)
          } else {
            setClientID(event.target.value)
          }
        }}
      >
        {sessionData.value.clients.map((client, idx) =>
          <MenuItem key={idx} value={client.clientID}>{client.name}</MenuItem>
        )}
        <Divider />
        <MenuItem key='new' value='new'>Criar novo cliente</MenuItem>
      </Select>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year', 'month']}
          label="Mês de referência"
          minDate={new Date('2024-01-01')}
          maxDate={new Date('2025-12-30')}
          value={date}
          onChange={(newValue) => {
            newValue != undefined && setDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ inputMode: 'numeric' }}
        label='Sessões'
        value={sessions ?? ''}
        error={showFailures && sessions == undefined}
        helperText={showFailures && sessions == undefined && "Campo obrigatório"}
        onChange={(event) => updateSessions(event.target.value)}
      />
      <Typography>valor por sessão: {client ? `R$ ${client.sessionPrice},00` : ''}</Typography>
      <Typography>valor total: {(client && sessions) ? `R$ ${client.sessionPrice * sessions},00` : ``}</Typography>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        label='Messagem para o usuário'
        type='text'
        value={notes}
        onChange={(event) => setNotes(event.target.value)}
      />
      <Button onClick={() => save()}>Salvar</Button>
    </Stack>
  </Box >
}