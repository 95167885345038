import { CopyAll } from "@mui/icons-material"
import { Stack, Typography, Tooltip, IconButton } from "@mui/material"
import React from "react"

type Props = {
  name: string
  value: string
  copy?: string
}

export default function InfoEntry({ name, value, copy }: Props) {
  const [open, setOpen] = React.useState(false);

  return <Stack direction='row' alignItems='center'>
    <Typography variant='subtitle1' flex='1'>
      {name}
    </Typography>
    <Typography variant='subtitle1'>
      {value}
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        onClose={() => setOpen(false)}
        leaveDelay={200}
        disableInteractive
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={"Copiado!"}
      >
        <IconButton
          sx={{ visibility: copy != undefined ? 'visible' : 'hidden' }}
          disabled={!window.isSecureContext}
          onClick={() => {
            if (copy != undefined) {
              navigator.clipboard.writeText(copy).then(() => {
                setOpen(true);
                setTimeout(() => setOpen(false), 300)
              });
            }
          }}
        >
          <CopyAll />
        </IconButton>
      </Tooltip>
    </Typography>
  </Stack >


}