import { Box } from "@mui/material";
import React from "react";
import { GetPaymentReceiptURL, PaymentReceiptURL } from "../../api/Payments"
import { AuthHolder } from "../../utils/Auth";
import { UseMount, UseState } from "../../utils/UseChange";
import { LoadingBackdrop, LoadingController } from "../LoadingBackdrop/LoadingBackdrop";

type Props = {
  authHolder: AuthHolder
  paymentID: string
}

export function PaymentReceiptViewer({ authHolder, paymentID }: Props) {
  const paymentReceiptURL = UseState<PaymentReceiptURL>({} as PaymentReceiptURL)
  const showImage = UseState(true)
  const loading = React.useRef(new LoadingController())

  UseMount(() => {
    loading.current.show();
    GetPaymentReceiptURL(authHolder, paymentID)
      .then(data => { console.log(data); paymentReceiptURL.set(data) })
      .catch(err => {
        loading.current.fail();
        showImage.set(false);
        console.error(err);
      });
  })

  return <Box>

    <LoadingBackdrop controller={loading.current} />
    {paymentReceiptURL.value.url?.length > 0 &&
      <img
        style={{ width: '100%', height: '100%', display: showImage.value ? 'null' : 'none' }}
        src={paymentReceiptURL.value.url}
        loading='lazy'
        onError={() => {
          loading.current.fail();
          showImage.set(false);
        }}
        onLoad={() => {
          loading.current.dismiss();
        }}
      />}
  </Box>
}