import { Box } from "@mui/material";
import React from "react";
import { NavigatorController } from "../Navigator/Navigator";
import { State } from "../../utils/UseChange";
import { CreatePaymentRequest, Payment } from "../../api/Payments";
import { LoadingBackdrop, LoadingController } from "../LoadingBackdrop/LoadingBackdrop";
import PaymentEditor from "./PaymentEditor";
import { SessionData } from "../../api/SessionData";
import { AuthHolder } from "../../utils/Auth";

type Props = {
  authHolder: AuthHolder
  sessionData: State<SessionData>
  injectedClientID?: string | undefined
  navigator: NavigatorController
  refresh?: () => void
}

export default function CreatePayment({ authHolder, sessionData, navigator, injectedClientID, refresh }: Props) {
  const loading = React.useRef(new LoadingController())

  const save = (payment: Payment) => {
    loading.current.show()
    CreatePaymentRequest(authHolder, payment).then((payment => {
      loading.current.success().then(() => {
        navigator.pop()
        var newData = sessionData.value
        newData.payments.unshift(payment)
        sessionData.set(newData)
        refresh != undefined && refresh()
      })
    })).catch(err => {
      console.error(err)
      loading.current.fail()
    })
  }

  return <Box>
    <LoadingBackdrop controller={loading.current} />
    <PaymentEditor authHolder={authHolder} sessionData={sessionData} navigator={navigator} injectedClientID={injectedClientID} savePayment={save} />
  </Box >
}