import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyAy4O0O1pcyv1JVkvsu_d5CtkPxgF5P348",
  authDomain: "dia1-1df68.firebaseapp.com",
  projectId: "dia1-1df68",
  storageBucket: "dia1-1df68.appspot.com",
  messagingSenderId: "439342524979",
  appId: "1:439342524979:web:0f3ed43805517b61555146",
  measurementId: "G-N6W3F0MHXW",
  prompt: "select_account",
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const ui = new firebaseui.auth.AuthUI(auth);

export class AuthHolder {
  user: firebase.User
  constructor(user: firebase.User) {
    this.user = user
  }

  getToken(): Promise<string> {
    return this.user.getIdToken()
  }
}

export function getUser(
  authorized: (user: firebase.User) => void,
  unauthorized?: () => void,
) {

  auth.onAuthStateChanged((user: firebase.User | null) => {
    if (user != null) {
      console.log(`retrieved user: ${user.email}`);
      authorized(user);
    }
    else {
      console.log("unauthorized");
      unauthorized && unauthorized();
    }
  })
}

export function signOut(): Promise<void> {
  return auth.signOut()
}

export function getUI() {
  return ui
}