import { Edit, Logout } from "@mui/icons-material";
import { Divider, Fab, IconButton, Modal, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { SessionData } from "../../api/SessionData";
import { signOut } from "../../utils/Auth";
import { State } from "../../utils/UseChange";
import { NavigatorController } from "../Navigator/Navigator";
import { UseTerms } from "../Documents/UseTerms";
import { PrivacyPolicies } from "../Documents/PrivacyPolicies";

type Props = {
  sessionData: State<SessionData>
  navigator: NavigatorController
}

export default function Settings({ navigator, sessionData }: Props) {
  const [editModal, setEditModal] = React.useState(false)

  const divisor = (title: string) => {
    return <Box marginTop={2}>
      <Divider />
      <Typography variant='h5' sx={{ marginTop: 2 }}>{title}</Typography>
    </Box>
  }
  const entry = (title: string, value: string) => {
    return <Box marginTop={1} marginLeft={2} marginRight={2}>
      <Typography variant='subtitle1' sx={{ marginBottom: -1, fontSize: '0.8rem' }}>{title}</Typography>
      <Typography variant='h6'>{value}</Typography>
    </Box>
  }

  return <Box>
    <Stack direction='column' sx={{ margin: 2 }} alignItems='left'>
      <Stack direction='row' alignItems='center' display='flex'>
        <Stack flexGrow='1' direction='column' alignItems='left'>
          <Typography variant='h5'>{sessionData.value.user.name}</Typography>
          <Typography variant='subtitle1'>{sessionData.value.user.email}</Typography>
        </Stack>
        <IconButton onClick={() => {
          signOut();
        }}>Sair <Logout sx={{ margin: 1 }} /></IconButton>
      </Stack>
      {divisor('Perfil')}
      {entry('Telefone', `${sessionData.value.user.phone.countryCode !== "55" ? `+${sessionData.value.user.phone.countryCode} ` : ''}(${sessionData.value.user.phone.regionalCode}) ${sessionData.value.user.phone.number}`)}
      {entry('Email', sessionData.value.user.email)}
      {entry('Profissão', sessionData.value.user.profession)}
      {divisor('Informações bancárias')}
      {entry('Pix', `${sessionData.value.user.pix.keyType}: ${sessionData.value.user.pix.key}`)}
      {divisor('Documentos')}
      <UseTerms style={{ fontWeight: '700', marginTop: 2, marginLeft: 12, marginRight: 2 }}>Termos de Uso</UseTerms>
      <PrivacyPolicies style={{ fontWeight: '700', marginTop: 2, marginLeft: 12, marginRight: 2 }}>Politicas de privacidade</PrivacyPolicies>
    </Stack>
    <Modal
      open={editModal}
      onClose={() => setEditModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant='h5' id="modal-modal-description">
          Para editar informações cadastrais, por favor entre em contato pelo menu 'Fale Conosco'
        </Typography>
      </Box>
    </Modal>
    <Fab color='primary' onClick={() => setEditModal(true)} sx={{ position: 'fixed', bottom: 72, right: 16 }} aria-label="add">
      <Edit />
    </Fab>
  </Box>
}