export const AuthError = "auth-error";
export const InternalError = "internal-error";
export const UnknownError = "unknown-error";

export function APIError(message, response) {
  this.message = message.length === 0 ? "failed to communicate with server" : message;
  this.status = response?.status ?? 500;

  if (this.status === 401 || this.status === 403) {
    this.type = AuthError;
  } else if (this.status >= 500) {
    this.type = InternalError;
  } else {
    this.type = UnknownError;
  }
  return this
}

APIError.prototype = Error.prototype;
