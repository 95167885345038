import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export function TestNow() {
  const theme = useTheme()
  const navigate = useNavigate();
  return <Box sx={{ width: '100%', height: '100%', padding: '5%', display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={{
        display: 'flex',
        height: '5%',
        alignItems: 'center',
        marginBottom: '10%',
      }}
    >
      <img style={{ height: theme.mixins.ch(3), width: 'auto', marginRight: '1%' }} src="logo.webp" />
      <Typography fontSize={theme.mixins.ch(3)} flexGrow={1} fontWeight='medium'>Dia1.io</Typography>
      <Button
        variant='contained'
        onClick={() => {
          navigate("/painel");
        }}
        sx={{
          textTransform: 'none',
          backgroundColor: '#0000',
          color: 'black',
          borderRadius: '100vh',
          border: '3px solid black',
          height: theme.mixins.ch(5),
          width: '15%',
          minWidth: '150px',
          fontSize: theme.mixins.ch(2),
        }}
      >Login</Button>
    </Box>

    <Box
      sx={{
        height: '60%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          width: '100%',
          flexDirection: 'column',
          display: 'flex',
        }}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Typography fontSize={theme.mixins.ch(3)} textTransform='none' width='60%' >
            <Typography fontSize='inherit' component='span' color='textSecondary'>Profissional Liberal: </Typography>
            <Typography fontSize='inherit' component='span'>automatize a parte burocrática do trabalho</Typography>
          </Typography>
          <Box width='30%' maxWidth='300px' marginLeft='10%'>
            <img style={{ width: '100%', height: '100%' }} src={"test-now.svg"} />
          </Box>
        </Box>
        <Typography flexGrow={0} fontSize={theme.mixins.ch(2)} width='60%'>Foque no que você adora fazer e deixe a parte chata de ser um profissional liberal conosco</Typography>
      </Box>
    </Box>

    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Button
        variant='contained'
        onClick={() => {
          navigate("/painel");
        }}
        sx={{
          height: theme.mixins.ch(4),
          minWidth: '200px',
          width: '30%',
          textTransform: 'none',
          borderRadius: '100vh',
          fontSize: theme.mixins.ch(2),
        }}>Teste grátis</Button>
    </Box>
  </Box >
}