import { API_HOST } from "../constants/Addresses"
import { AuthHolder } from "../utils/Auth"
import { Phone } from "./Entities"
import { APIError } from "./Errors"
import { PostRequest, PutRequest } from "./Utils"

export type Client = {
  clientID: string
  userID: string
  name: string
  phone: Phone
  sessionPrice: number
}

export const CreateClientRequest = async (auth: AuthHolder, client: Client): Promise<Client> => {
  const idToken = await auth.getToken();

  const result = await PostRequest<Client | undefined>(
    `${API_HOST}`,
    `clients`,
    client,
    {
      "authorization": `bearer ${idToken}`
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as Client;
  return response;
}

export const EditClientRequest = async (auth: AuthHolder, client: Client): Promise<Client> => {
  const idToken = await auth.getToken();

  const result = await PutRequest<Client | undefined>(
    `${API_HOST}`,
    `clients/${client.clientID}`,
    client,
    {
      "authorization": `bearer ${idToken}`
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as Client;
  return response;
}