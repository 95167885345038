import { Delete, MoreVert, PendingActionsOutlined, PriceCheck } from "@mui/icons-material"
import { Box, Stack, Typography, Button, Divider, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Backdrop, SpeedDial, SpeedDialAction } from "@mui/material"
import React from "react"
import { DeletePaymentRequest, Payment } from "../../api/Payments"
import { ReceiptStatus } from "../../api/Receipts"
import { SessionData } from "../../api/SessionData"
import { AuthHolder } from "../../utils/Auth"
import { State } from "../../utils/UseChange"
import { ClientMessage, WhatsappMessage } from "../../utils/Whatsapp"
import { LoadingBackdrop, LoadingController } from "../LoadingBackdrop/LoadingBackdrop"
import { NavigatorController } from "../Navigator/Navigator"
import { PaymentReceiptViewer } from "./PaymentReceiptViewer"

type Props = {
  authHolder: AuthHolder
  navigator: NavigatorController
  payment: Payment
  sessionData: State<SessionData>
}

export default function PaymentDetails({ authHolder, navigator, payment, sessionData }: Props) {
  const loading = React.useRef(new LoadingController())
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [speedDial, setSpeedDial] = React.useState(false);

  const handleDelete = () => {
    setDeleteDialog(false)
    loading.current.show()
    DeletePaymentRequest(authHolder, payment).then(() => {
      var newData = sessionData.value
      newData.payments = newData.payments.filter(p => p.paymentID !== payment.paymentID)
      sessionData.set(newData)
      loading.current.success().finally(() => {
        navigator.pop()
      })
    }).catch(() => {
      loading.current.fail()
    })
  }

  //TODO client could be undefined
  //TODO find in map instead of filter through array
  const client = sessionData.value.clients.find(c => c.clientID === payment.clientID)
  if (client == undefined) {
    console.error('undefined client')
    return <a>Estado inválido, por favor, fale conosco</a>
  }

  const divisor = (title: string) => {
    return <Box marginTop={2}>
      <Divider />
      <Typography variant='h5' sx={{ marginTop: 2 }}>{title}</Typography>
    </Box>
  }
  const entry = (title: string, ...value: Array<string>) => {
    return <Box marginTop={1} marginLeft={2} marginRight={2}>
      <Typography variant='subtitle1' sx={{ marginBottom: -1, fontSize: '0.8rem' }}>{title}</Typography>
      {value.map((v, i) => {
        return <Typography key={`typography-entry-${i}`} variant='h6'>{v}</Typography>
      })}
    </Box>
  }

  const openReceipt = (payment: Payment) => {
    navigator.push('Recibo', <PaymentReceiptViewer authHolder={authHolder} paymentID={payment.paymentID} />)
  }

  const date = new Date();
  date.setDate(1);
  date.setMonth(payment.month);
  const monthShort = date.toLocaleString('pt-BR', { month: 'short' }).replace('\.', '').toLocaleUpperCase();
  const monthLong = date.toLocaleString('pt-BR', { month: 'long' }).replace('\.', '').replace(/^./, str => str.toUpperCase());

  const receiptDate = new Date(payment.receiptTS ?? '');
  const receiptMonth = receiptDate.toLocaleString('pt-BR', { month: 'long' }).replace('\.', '').replace(/^./, str => str.toUpperCase());

  return <Box height='100%' width='100%' display='flex' alignContent='center' justifyContent='center'>
    <LoadingBackdrop controller={loading.current} />
    <Stack height='100%' width='100%' direction='column' marginTop={6} display='flex' alignItems='center' spacing={2}>
      {payment.receiptTS == undefined
        ? <PendingActionsOutlined sx={{ height: 100, width: 100, marginTop: 4, color: '#a90' }} />
        : <PriceCheck sx={{ height: 100, width: 100, marginTop: 4, color: '#060' }} />}
      <Typography variant='h4' align='center'>{client.name}</Typography>
      <Typography variant='h4' align='center'>R$ {payment.totalPrice},00</Typography>
      <Typography variant='h4' align='center'>{monthShort} {payment.year}</Typography>
      {payment.receiptStatus !== ReceiptStatus.Uploaded ?
        <Button variant='outlined' onClick={() => WhatsappMessage(client.phone, ClientMessage(client.name, monthLong, payment.notes, payment.paymentID))}>
          Compartilhar via whatsapp
        </Button>
        :
        <Button variant='outlined' onClick={(e) => {
          e.preventDefault();
          openReceipt(payment);
        }}>
          Ver comprovante
        </Button>
      }
      <Box width='100%' paddingTop={2} paddingLeft={2}>
        {divisor('Detalhes')}
        {entry('Status do pagamento',
          payment.receiptStatus !== ReceiptStatus.Uploaded ? 'Pagamento pendente' : `Comprovante enviado`,
          payment.receiptStatus !== ReceiptStatus.Uploaded ? '' : `${receiptDate.getDate()} de ${receiptMonth} de ${receiptDate.getFullYear()}`)}
        {entry('Valor total', `R$ ${payment.totalPrice}`)}
        {entry('Valor da sessão', `R$ ${payment.sessionPrice}`)}
        {entry('Número de sessões', `${payment.sessionCount}`)}
        {entry('Mês de referência', `${monthLong} de ${payment.year}`)}
        {entry('Mensagem para o usuário', payment.notes === "" ? "---" : payment.notes)}
      </Box>
    </Stack>


    <Dialog onClose={() => setDeleteDialog(false)} open={deleteDialog}>
      <DialogTitle>Deletar pagamento</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você está prestes a deletar um pagamento. Deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialog(false)}>Cancelar</Button>
        <Button onClick={() => handleDelete()} autoFocus>
          Apagar
        </Button>
      </DialogActions>
    </Dialog>

    <Backdrop open={speedDial} />
    <SpeedDial
      ariaLabel="speed dial"
      sx={{ color: 'secondary', position: 'fixed', bottom: 72, right: 16 }}
      icon={<MoreVert />}
      onClose={() => setSpeedDial(false)}
      onOpen={() => setSpeedDial(true)}
      open={speedDial}
    >
      <SpeedDialAction
        icon={<Delete />}
        tooltipTitle='Deletar pagamento'
        tooltipOpen
        onClick={() => setDeleteDialog(true)}
      />
    </SpeedDial>
  </Box>
}