import { API_HOST } from "../constants/Addresses"
import { APIError } from "./Errors"
import { PixData } from "./User"
import { GetRequest, PostRequest } from "./Utils"

export const ReceiptStatus = {
  Pending: 'pending',
  Uploaded: 'uploaded',
}

export type PaymentPublic = {
  paymentID: string
  shortClientName: string
  professionalName: string

  sessionPrice: number
  sessionCount: number
  totalPrice: number

  month: number
  year: number

  receiptStatus: string
  notes: string

  pix: PixData
}

export type ReceiptIntent = {
  paymentID: string
  receiptUploadPath: string
  receiptUploadURL: string
  receiptUploadURLExpirationTS: string
}

export type ReceiptCommit = {
  paymentID: string
}

export const GetPaymentPublicData = async (paymentID: string): Promise<PaymentPublic> => {
  const result = await GetRequest<PaymentPublic | undefined>(
    `${API_HOST}`,
    `payments/${paymentID}/public`,
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as PaymentPublic;
  return response;
}

export const PostReceiptIntent = async (paymentID: string): Promise<ReceiptIntent> => {
  const result = await PostRequest<ReceiptIntent | undefined>(
    `${API_HOST}`,
    `payments/${paymentID}/receipt/intent`,
    {},
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as ReceiptIntent;
  return response;
}

export const PostReceiptCommit = async (paymentID: string, uploadPath: string): Promise<ReceiptIntent> => {
  const result = await PostRequest<ReceiptIntent | undefined>(
    `${API_HOST}`,
    `payments/${paymentID}/receipt/commit`,
    {
      receiptUploadPath: uploadPath,
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as ReceiptIntent;
  return response;
}