import { Add, ArrowRight } from "@mui/icons-material"
import { Box, Fab, List, Stack, Typography } from "@mui/material"
import { Client } from "../../api/Clients"
import { SessionData } from "../../api/SessionData"
import { AuthHolder } from "../../utils/Auth"
import { State } from "../../utils/UseChange"
import { AvatarImage } from "../AvatarImage/AvatarImage"
import { NavigatorController } from "../Navigator/Navigator"
import ClientDetails from "./ClientDetails"
import CreateClient from "./CreateClient"

type Props = {
  authHolder: AuthHolder
  navigator: NavigatorController
  sessionData: State<SessionData>
}

export default function Clients({ authHolder, sessionData, navigator }: Props) {
  const createClient = () => {
    navigator.push("Criar cliente", <CreateClient authHolder={authHolder} navigator={navigator} sessionData={sessionData} />)
  }

  const openDetails = (client: Client) => {
    navigator.push('Detalhes',
      <ClientDetails
        authHolder={authHolder}
        sessionData={sessionData}
        client={client}
        navigator={navigator}
      />)
  }

  return <Box width='100%'>
    {sessionData.value.clients.length === 0
      ? <Box display='flex' width='100%' justifyContent='center' marginTop={3}>
        <Typography>Crie seu primeiro cliente!</Typography>
      </Box>
      : sessionData.value.clients.map((client, idx) =>
        <Box
          key={idx}
          style={{ cursor: 'pointer' }}
          onClick={() => openDetails(client)}
        >
          <Stack direction='row' alignItems='center' display='flex' spacing={2} marginLeft={1} margin={2}>
            <AvatarImage fontSize={20} sx={{ marginTop: 1, marginBottom: 1, height: 50, width: 50 }} name={client.name} />
            <Typography variant='h6' flexGrow='1'>{client.name}</Typography>
            <ArrowRight />
          </Stack>
        </Box>)
    }
    <Fab color='primary' onClick={() => createClient()} sx={{ position: 'fixed', bottom: 72, right: 16 }} aria-label="add">
      <Add />
    </Fab>
  </Box >
}