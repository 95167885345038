import { API_HOST } from "../constants/Addresses"
import { AuthHolder } from "../utils/Auth"
import { APIError } from "./Errors"
import { DeleteRequest, GetRequest, PostRequest } from "./Utils"


export type PaymentReceiptURL = {
  paymentID: string
  userID: string
  url: string
  expirationTS: string
}

export type Payment = {
  paymentID: string
  userID: string
  clientID: string
  sessionPrice: number
  sessionCount: number
  totalPrice: number
  month: number
  year: number
  receiptStatus: string
  receiptPath: string
  receiptTS: string | undefined
  notes: string
}

export const GetPaymentReceiptURL = async (auth: AuthHolder, paymentID: string): Promise<PaymentReceiptURL> => {
  const idToken = await auth.getToken();

  const result = await GetRequest<PaymentReceiptURL | undefined>(
    `${API_HOST}`,
    `payments/${paymentID}/receipt`,
    undefined,
    {
      "authorization": `bearer ${idToken}`
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as PaymentReceiptURL;
  return response;
}


export const CreatePaymentRequest = async (auth: AuthHolder, payment: Payment): Promise<Payment> => {
  const idToken = await auth.getToken();

  const result = await PostRequest<Payment | undefined>(
    `${API_HOST}`,
    `payments`,
    payment,
    {
      "authorization": `bearer ${idToken}`
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as Payment;
  return response;
}

export const DeletePaymentRequest = async (auth: AuthHolder, payment: Payment): Promise<undefined> => {
  const idToken = await auth.getToken();

  const result = await DeleteRequest<undefined>(
    `${API_HOST}`,
    `payments/${payment.paymentID}`,
    {
      "authorization": `bearer ${idToken}`
    },
  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as undefined;
  return response;
}
