import { Avatar, SxProps, Theme, Typography } from "@mui/material";

interface Props {
  name: string
  img?: string
  sx?: SxProps<Theme>
  fontSize: number
}
export function AvatarImage({ name, img, sx, fontSize }: Props) {
  name = name.replace(/\s+/g, ' ').trim()
  var smallName = name
  const splitName = name.split(' ')
  if (splitName?.length === 1) {
    smallName = name?.slice(0, 2)
  } else if (splitName?.length >= 2) {
    smallName = `${splitName[0][0]}${splitName[1][0]}`
  }

  return <Avatar
    sx={{
      ...sx,
      bgcolor: '#0f1048',
    }}
    children={<Typography fontSize={fontSize}>{smallName}</Typography>}
    src={img}
  />
}
