import { Close, KeyboardArrowLeft, KeyboardArrowRight, Logout } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputLabel, MenuItem, MobileStepper, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { CreateUser, User } from "../../api/User";
import { AuthHolder, signOut } from "../../utils/Auth";
import { cpfRegexComplete, emailRegexComplete, getPhoneParts, phoneRegexComplete, updateCPF, updatePhone } from "../../utils/PhoneNumber";
import { AvatarImage } from "../AvatarImage/AvatarImage";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";
import { LoadingBackdrop, LoadingController } from "../LoadingBackdrop/LoadingBackdrop";
import { UseTerms } from "../Documents/UseTerms";
import { PrivacyPolicies } from "../Documents/PrivacyPolicies";

type Props = {
  profileImg?: string
  defaultName?: string
  authHolder: AuthHolder
  refreshSession: () => void
}

export function SignUp({ profileImg, defaultName, authHolder, refreshSession }: Props) {
  const loading = React.useRef(new LoadingController());
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = React.useState(defaultName ?? "");
  const [acceptConditions, setAcceptConditions] = React.useState(false)
  const [useTerms, setUseTerms] = React.useState(false)
  const [privacyPolicies, setPrivacyPolicies] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [pixType, setPixType] = React.useState("");
  const [pixKey, setPixKey] = React.useState("");
  const [showFailures, setShowFailures] = React.useState(false);
  const maxSteps = 3;
  const [pixKeyError, setPixKeyError] = React.useState("");

  const lastStep = () => {
    return activeStep + 1 === maxSteps
  }

  const handleNext = () => {
    var failed = false;
    if (activeStep === 0 && (name.length === 0 || !acceptConditions)) {
      failed = true
    }
    if (activeStep === 1 && !phoneRegexComplete.test(phoneNumber)) {
      failed = true
    }
    if (activeStep === 1 && profession.length === 0) {
      failed = true
    }
    if (activeStep === 2) {
      if (pixType.length === 0) {
        failed = true
      }

      if (pixKey.length === 0) {
        failed = true
        setPixKeyError("Campo obrigatório")
      }
      else {
        switch (pixType) {
          case "cpf":
            if (!cpfRegexComplete.test(pixKey)) {
              setPixKeyError("123.456.789-00")
              failed = true
            }
            break;
          case "phone":
            if (!phoneRegexComplete.test(pixKey)) {
              setPixKeyError("(00) 9 1234-5678")
              failed = true
            }
            break;
          case "email":
            if (!emailRegexComplete.test(pixKey)) {
              setPixKeyError("email@exemplo.com")
              failed = true
            }
            break;
          case "random":
            break;
        }
      }
    }

    if (failed) {
      setShowFailures(true);
      return;
    }
    setShowFailures(false);

    if (lastStep()) {
      handleSubmit()
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    const [extractedRegional, extractedPhone] = getPhoneParts(phoneNumber)
    const newUser: User = {
      userID: "",
      firebaseID: authHolder.user.uid,
      name: name ?? "",
      phone: {
        countryCode: "55",
        regionalCode: extractedRegional ?? "",
        number: extractedPhone ?? "",
      },
      email: "",
      pix: {
        key: pixKey ?? "",
        keyType: pixType ?? "",
      },
      profession: profession ?? "",
    }

    loading.current.show()
    CreateUser(authHolder, newUser).then(savedUser => {
      console.log(savedUser);
      loading.current.success().then(() => {
        refreshSession()
      })
    }).catch(err => {
      console.error(err);
      loading.current.fail();
    })
  }

  const getStep = (step: number) => {
    switch (step) {
      case 0:
        return <Stack direction='column' spacing={2}>
          <TextField
            id='name'
            InputLabelProps={{
              shrink: true,
            }}
            label='Nome'
            value={name}
            error={showFailures && name.length === 0}
            helperText={showFailures && name.length === 0 && 'Campo vazio'}
            onChange={(event) => setName(event.target.value)}
          />
          <FormControl error>
            <FormControlLabel
              control={<Checkbox checked={acceptConditions} onChange={(e) => setAcceptConditions(e.target.checked)} />}
              label={<>Aceito os <UseTerms>termos de uso</UseTerms> e as <PrivacyPolicies>politicas de privacidade</PrivacyPolicies></>}
            />
            <FormHelperText sx={{ visibility: (showFailures && !acceptConditions) ? 'visible' : 'hidden' }}>É necessário aceitar para continuar</FormHelperText>
          </FormControl>
        </Stack>
      case 1:
        return <Stack direction='column' spacing={2}>
          <TextField
            id='phone-number'
            InputLabelProps={{
              shrink: true,
            }}
            label='Telefone'
            inputProps={{ inputMode: 'numeric' }}
            value={phoneNumber}
            error={showFailures && !phoneRegexComplete.test(phoneNumber)}
            helperText={showFailures && !phoneRegexComplete.test(phoneNumber) && '(00) 9 1234-5678'}
            onChange={(event) => updatePhone(event.target.value, setPhoneNumber)}
          />
          <TextField
            id='profession'
            InputLabelProps={{
              shrink: true,
            }}
            label='Profissão'
            value={profession}
            error={showFailures && profession.length === 0}
            helperText={showFailures && profession.length === 0 && 'Campo obrigatório'}
            onChange={(event) => setProfession(event.target.value)}
          />
        </Stack>
      case 2:
        return <Stack direction='column' spacing={2}>
          <FormControl
            error={pixType.length === 0 && showFailures}
          >
            <InputLabel variant='outlined'>Tipo da chave Pix</InputLabel>
            <Select
              id='pix-type'
              value={pixType}
              variant='outlined'
              label='Tipo da chave Pix'
              onChange={(event) => {
                const value = event.target.value
                if (value === "phone") {
                  setPixKey(phoneNumber)
                } else {
                  setPixKey('')
                }
                setPixKeyError('')
                setPixType(value)
              }}
            >
              <MenuItem key={"cpf"} value={"cpf"}>{"CPF"}</MenuItem>
              <MenuItem key={"phone"} value={"phone"}>{"Telefone"}</MenuItem>
              <MenuItem key={"email"} value={"email"}>{"Email"}</MenuItem>
              <MenuItem key={"random"} value={"random"}>{"Chave aleatória"}</MenuItem>
            </Select>
            {showFailures && pixType.length === 0 && <FormHelperText>Campo obrigatório</FormHelperText>}
          </FormControl>
          <TextField
            id='pix-key'
            inputProps={{
              inputMode: {
                "cpf": "numeric" as "numeric",
                "phone": "numeric" as "numeric",
                "email": "email" as "email",
                "random": "text" as "text",
              }[pixType] ?? "text"
            }}
            label='Chave Pix'
            value={pixKey}
            error={showFailures && pixKeyError.length !== 0}
            helperText={showFailures && pixKeyError}
            onChange={(event) => {
              const value = event.target.value
              switch (pixType) {
                case "cpf": updateCPF(value, setPixKey)
                  break;
                case "phone": updatePhone(value, setPixKey)
                  break;
                case "email": setPixKey(value)
                  break;
                case "random": setPixKey(value)
                  break;
              }
            }
            }
            disabled={pixType === ""}
          />
        </Stack>
      default:
        return <></>
    }
  }

  return <Box height='100%' width='100%' display='flex' alignContent='center' justifyContent='center'>

    <IconButton sx={{ position: 'absolute', right: '0px' }} onClick={() => {
      signOut();
    }}>Sair <Logout sx={{ margin: 1 }} /></IconButton>
    <LoadingBackdrop controller={loading.current} />
    <Stack width='100%' height='100%' direction='column' margin={6} display='flex' alignItems='center'>
      <CompanyAvatar size={100} />
      <Typography variant="h4">Bem vindo!</Typography>
      <AvatarImage fontSize={20} sx={{ height: 50, width: 50 }} name={name ?? ""} img={profileImg} />
      <Box sx={{ flexGrow: 1, marginTop: 3, width: '100%' }}>
        {getStep(activeStep)}
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={false}
            >
              {lastStep() ? "Salvar" : "Avançar"}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Voltar
            </Button>
          }
        />
      </Box>
    </Stack>
  </Box>
}