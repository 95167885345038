import { Avatar } from "@mui/material"

type Props = {
  size: number
}

export default function CompanyAvatar({ size }: Props) {
  //TODO fetch color from theme
  return <Avatar sx={{ height: size, width: size, margin: 4, backgroundColor: '#0f1048' }}>
    <img style={{ height: '100%', width: '100%', objectFit: 'fill' }} src='/logo192.png' />
  </Avatar>
}