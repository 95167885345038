import { Box, Card, Typography, useTheme } from "@mui/material";

export function HelpYou() {
  const theme = useTheme()

  const CCard = ({ title, text }: { title: string, text: string }) => {
    return <>
      <Card
        sx={{
          textAlign: 'center',
          flexGrow: 1,
          margin: '5%',
          padding: '2%',
        }}>
        <Box
          sx={{
            widht: '100%',
            height: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography fontSize={theme.mixins.cm(4, 2.5)} fontWeight='medium' color='textSecondary'>{title}</Typography>
        </Box>
        <Box
          sx={{
            widht: '100%',
            height: '50%',
            display: 'flex',
            alignItems: 'begin',
            justifyContent: 'center',
          }}>
          <Typography fontSize={theme.mixins.cm(3, 1.9)}>{text}</Typography>
        </Box>
      </Card>
    </>
  }

  return <Box sx={{ width: '100%', height: '100%', padding: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
      }}>
      <Typography textAlign='center' fontSize={theme.mixins.ch(4)} fontWeight='medium'>Como o Dia1 ajuda você</Typography>
      <Typography textAlign='center' fontSize={theme.mixins.ch(2)}>QUEREMOS LIBERTÁ-LOS DAQUELAS TAREFAS CHATAS QUE FAZEM PARTE DE SER UM</Typography>
      <Typography textAlign='center' fontSize={theme.mixins.ch(2)}>PROFISSIONAL LIBERAL</Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        flexGrow: 1,
      }}>
      <CCard title="AUTOMAÇÃO DE COBRANÇA" text="Com o Dia1, você automatiza o cálculo, envio e acompanhamento das cobranças. Receba de seus clientes na data certa com o mínimo esforço." />
      <CCard title="EMISSÃO DE NOTAS FISCAIS" text="(Disponível em breve) Emita e envie NFs com apenas 1 clique. Diga adeus àquela vida de várias abas abertas ou de ficar copiando e colando CPFs." />
    </Box >
  </Box >
}