import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { UseMount } from "../../utils/UseChange";

type Entry = {
  node: React.ReactNode
  title: string
}

export class NavigatorController {
  shouldRender = true
  stack = Array<Entry>()
  setCount: Dispatch<SetStateAction<number>> = (number) => { console.error('navigation ignored') }

  push(title: string, node: React.ReactNode) {
    this.stack.push({ title, node })
    this.update()
  }
  pop() {
    this.stack.pop()
    this.update()
  }
  clear() {
    this.stack.length = 0
    this.update()
  }
  update() {
    this.setCount(this.stack.length)
  }
  bind(setCount: Dispatch<SetStateAction<number>>) {
    this.setCount = setCount;
    this.setCount(this.stack.length);
  }
}

type Props = {
  controller: NavigatorController
  children: React.ReactNode
}
export function Navigator({ controller, children }: Props) {
  const [count, setCount] = React.useState(0)

  UseMount(() => {
    controller.bind(setCount);
  })

  return <Box>
    {count > 0 ?
      (<Box sx={{ height: '100%', width: '100%', }}>
        {controller.stack.map((entry, idx) => {
          return <Paper key={idx} sx={{ display: idx === controller.stack.length - 1 ? null : 'none', height: '100%', width: '100%' }}>
            <Stack direction='column'>
              <Stack direction='row' alignItems='center'>
                <IconButton onClick={() => controller.pop()}><ArrowBack fontSize='large' /></IconButton>
                <Typography variant='h5'>{entry.title}</Typography>
              </Stack>
              {entry.node}
            </Stack>
          </Paper>
        }
        )}
      </Box>) :
      children
    }
  </Box>
}