import { API_HOST } from "../constants/Addresses";
import { AuthHolder } from "../utils/Auth";
import { Client } from "./Clients";
import { APIError } from "./Errors";
import { Payment } from "./Payments";
import { User } from "./User";
import { PostRequest } from "./Utils";


export type SessionData = {
  userCreated: boolean
  user: User
  payments: Array<Payment>
  clients: Array<Client>
}

export const GetSessionData = async (auth: AuthHolder): Promise<SessionData> => {
  const idToken = await auth.getToken()

  const result = await PostRequest<SessionData | undefined>(
    `${API_HOST}`,
    'sessions',
    {
      idToken,
    },
    {
      "authorization": `bearer ${idToken}`
    },

  );

  if (result instanceof APIError) {
    throw result
  }

  const response = result as SessionData;
  return response;
}