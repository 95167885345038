import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material"
import React from "react"
import { Client } from "../../api/Clients"
import { SessionData } from "../../api/SessionData"
import { buildPhone, getPhoneParts, phoneRegexComplete, updatePhone } from "../../utils/PhoneNumber"
import { State } from "../../utils/UseChange"

type Props = {
  sessionData: State<SessionData>
  client?: Client
  saveClient: (client: Client) => void
}
export default function ClientEditor({ sessionData, client, saveClient }: Props) {
  const [name, setName] = React.useState(client?.name ?? '')
  const [phone, setPhone] = React.useState(buildPhone((client?.phone.regionalCode ?? '') + (client?.phone.number ?? '')))
  const [price, setPrice] = React.useState<number | undefined>(client?.sessionPrice)
  const [showFailures, setShowFailures] = React.useState(false)

  const updatePrice = (newValue: number | string) => {
    newValue = `${newValue}`.replaceAll(/[^0-9]/g, '')
    if (newValue === '') {
      setPrice(undefined)
      return
    }
    setPrice(+newValue)
  }

  const save = () => {
    let failed = false
    if (name.length === 0) {
      failed = true
    }
    if (!phoneRegexComplete.test(phone)) {
      failed = true
    }
    if (price == undefined) {
      failed = true
    }

    if (failed) {
      setShowFailures(true)
      return
    }
    setShowFailures(false)

    const [extractedRegional, extractedPhone] = getPhoneParts(phone)

    client = {
      clientID: client?.clientID ?? '',
      userID: sessionData.value.user.userID,
      name,
      phone: {
        countryCode: "55",
        regionalCode: extractedRegional,
        number: extractedPhone,
      },
      sessionPrice: price ?? 0
    }

    saveClient(client)
  }
  return <Box justifyContent='center' display='flex'>
    <Stack direction='column' margin={6} spacing={2}>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        label='Nome'
        value={name}
        error={showFailures && name.length === 0}
        helperText={showFailures && name.length === 0 && 'Campo obrigatório'}
        onChange={(event) => setName(event.target.value)}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ inputMode: 'numeric' }}
        label='Número'
        value={phone}
        error={showFailures && !phoneRegexComplete.test(phone)}
        helperText={showFailures && !phoneRegexComplete.test(phone) && '(00) 9 1234-5678'}
        onChange={(event) => updatePhone(event.target.value, setPhone)}
      />
      <TextField
        InputProps={{
          startAdornment: (<InputAdornment position="start">R$</InputAdornment>),
        }}
        inputProps={{ inputMode: 'numeric' }}
        label='Valor da sessão'
        value={price ?? ''}
        error={showFailures && price == undefined}
        helperText={showFailures && price == undefined && 'Campo obrigatório'}
        onChange={(event) => updatePrice(event.target.value)}
      />
      <Button onClick={() => save()}>Salvar</Button>
    </Stack>
  </Box>
}