import { Edit, MoreVert, RequestQuote } from "@mui/icons-material"
import { Backdrop, Box, Button, Divider, SpeedDial, SpeedDialAction, Stack, Typography } from "@mui/material"
import React from "react"
import { Client } from "../../api/Clients"
import { SessionData } from "../../api/SessionData"
import { AuthHolder } from "../../utils/Auth"
import { State, UseState } from "../../utils/UseChange"
import { WhatsappMessage } from "../../utils/Whatsapp"
import { AvatarImage } from "../AvatarImage/AvatarImage"
import { NavigatorController } from "../Navigator/Navigator"
import CreatePayment from "../Payments/CreatePayment"
import PaymentsList from "../Payments/PaymentsList"
import EditClient from "./EditClient"

type Props = {
  authHolder: AuthHolder
  navigator: NavigatorController
  sessionData: State<SessionData>
  client: Client
}
export default function ClientDetails({ authHolder, navigator, sessionData, client }: Props) {
  const [refresh, setRefresh] = React.useState(0)
  const [speedDial, setSpeedDial] = React.useState(false);
  const clientState = UseState(client)
  const updateRefresh = () => {
    setRefresh(refresh + 1)
  }
  const editClient = () => {
    navigator.push(`Editar - ${clientState.value.name}`, <EditClient authHolder={authHolder} sessionData={sessionData} client={clientState} navigator={navigator} />)
  }
  const createPayment = () => {
    navigator.push('Criar pagamento', <CreatePayment authHolder={authHolder} sessionData={sessionData} navigator={navigator} injectedClientID={clientState.value.clientID} refresh={updateRefresh} />)
  }

  return <Box height='100%' width='100%' display='flex' alignContent='center' justifyContent='center'>
    <Stack height='100%' width='100%' direction='column' marginTop={6} display='flex' alignItems='center' spacing={2}>
      <AvatarImage fontSize={40} name={clientState.value.name} sx={{ height: 100, width: 100, marginTop: 4 }} />
      <Typography variant='h4' align='center'>{clientState.value.name}</Typography>
      <Button variant='outlined' onClick={() => WhatsappMessage(clientState.value.phone)}>
        WhatsApp: ({clientState.value.phone.regionalCode}) {clientState.value.phone.number}
      </Button>
      <Button variant='outlined' onClick={createPayment}>
        Valor por sessão: R$ {clientState.value.sessionPrice}
      </Button>
      <Box paddingTop={2} paddingLeft={2} width='100%'>
        <PaymentsList authHolder={authHolder} navigator={navigator} sessionData={sessionData} filter={(payment => payment.clientID === clientState.value.clientID)} />
      </Box>
    </Stack>

    <Backdrop open={speedDial} />
    <SpeedDial
      ariaLabel="speed dial"
      sx={{ color: 'secondary', position: 'fixed', bottom: 72, right: 16 }}
      icon={<MoreVert />}
      onClose={() => setSpeedDial(false)}
      onOpen={() => setSpeedDial(true)}
      open={speedDial}
    >
      <SpeedDialAction
        icon={<RequestQuote />}
        tooltipTitle='Criar pagamento'
        tooltipOpen
        onClick={createPayment}
      />
      <SpeedDialAction
        icon={<Edit />}
        tooltipTitle='Editar cliente'
        tooltipOpen
        onClick={editClient}
      />
    </SpeedDial>
  </Box>
}