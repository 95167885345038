import { Box } from "@mui/material";
import { ForWhom } from "./ForWhom";
import { HelpYou } from "./HelpYou";
import { TestNow } from "./TestNow";
import { WhyUse } from "./WhyUse";


export function LandingPage() {
  const Section = ({ children, dark }: { children: JSX.Element, dark?: boolean }) => {
    return <Box sx={{
      width: '100%',
      backgroundColor: dark ? 'primary.dark' : 'background.default',
      color: dark ? 'secondary.contrastText' : 'primary.dark',
    }}
    >
      <Box sx={{
        height: '80vh',
        minHeight: "calc(0.8 * 640px)",
        margin: '0 auto',
        maxWidth: "max(130vh, 640px)",
        width: "100%",
      }}>
        {children}
      </Box>
    </Box>
  }

  return < Box sx={{
    height: '100vh',
    width: '100%',
    backgroundColor: "pink",
    minWidth: "320px",
    minHeight: "320px",
  }} >
    <Section>
      <TestNow />
    </Section>
    <Section dark>
      <HelpYou />
    </Section>
    <Section>
      <WhyUse />
    </Section>
    <Section>
      <ForWhom />
    </Section>
  </Box >
}